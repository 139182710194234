<template>
    <div class="Customer-card-body mt-5">
        <h3 class="mb-4">Edit Profile</h3>
        <div class="card p-4 shadow-sm">
            <form @submit.prevent="submitForm">
                <div class="row">
                    <div class="col-md-6">
                        <!-- Name Field -->
                        <div class="form-group">
                            <label for="name">Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" id="name" v-model="formData.name" />
                            <small v-if="errors.name" class="text-danger">{{ errors.name }}</small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- Email Address Field -->
                        <div class="form-group">
                            <label for="email">Email Address <span class="text-danger">*</span></label>
                            <input type="email" class="form-control" id="email" v-model="formData.email" />
                            <small v-if="errors.email" class="text-danger">{{ errors.email }}</small>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <!-- Password Field -->
                        <div class="form-group">
                            <label for="password">Password</label>
                            <input type="password" class="form-control" id="password" v-model="formData.password" />
                            <small v-if="errors.password" class="text-danger">{{ errors.password }}</small>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- Confirm Password Field -->
                        <div class="form-group">
                            <label for="confirmPassword">Confirm Password</label>
                            <input type="password" class="form-control" id="confirmPassword"
                                v-model="formData.confirmPassword" />
                            <small v-if="errors.confirmPassword" class="text-danger">{{ errors.confirmPassword }}</small>
                        </div>
                    </div>
                </div>
                

                <!-- Profile Image Field -->
                <div class="form-group row mt-3">
                    <div class="col-md-3">
                        <label for="profileImage">Profile Photo</label>
                        <input type="file" class="form-control-file" accept=".jpg, .jpeg, .png" id="profileImage"
                            @change="handleFileUpload" />
                        <small v-if="errors.profileImage" class="text-danger">{{ errors.profileImage }}</small>
                    </div>
                    <div class="col-md-3">
                        <div class="d-flex justify-content-center align-items-center gap-2 flex-column" style="width: 150px;">
                            <img :src="formData.avatar || defaultProfilePicture" alt="Profile Photo" class="img-thumbnail"
                                style="width: 150px; height: 150px;border-radius: 50%;" />

                                <!-- Remove Image Link -->
                            <div class="form-group mt-2" style="width: 100%;">
                                <a href="#" @click.prevent="removeImage" class="text-primary remove-btn btn-primary">Remove Image</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6"></div>
                </div>

                <!-- Submit Button -->
                <div class="form-group mt-3 text-right">
                    <button type="submit" class="btn btn-primary update-btn">Update</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import API from '@/api';

export default {
    data() {
        return {
            formData: {
                name: '',
                email: '',
                phone: '',
                password: '',
                confirmPassword: '',
                avatar: null,
                avatar_preview: null,
                originalAvatar: null,
            },
            defaultProfilePicture: 'https://staging.cheapcomforts.com/public/simp.svg',
            removeImageFlag: false,
            errors: {},
        };
    },
    methods: {
        handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.formData.avatar = file;
                this.formData.avatar_preview = URL.createObjectURL(file);
            }
        },
        onProfileChange(event) {
            const file = event.target.files[0];
            this.formData.avatar = file;
            this.formData.avatar_preview = URL.createObjectURL(file);
        },
        removeImage() {
            this.formData.avatar = null;  // Set avatar to null to trigger removal
            this.formData.avatar_preview = null;
            document.getElementById('profileImage').value = '';
        },

        validateForm() {
            this.errors = {};

            if (!this.formData.name) {
                this.errors.name = "Name is required.";
            }

            if (!this.formData.email) {
                this.errors.email = "Email is required.";
            }

            if (this.formData.password) {
                if (this.formData.password !== this.formData.confirmPassword) {
                    this.errors.confirmPassword = "Passwords do not match.";
                }
            }

            return Object.keys(this.errors).length === 0;
        },

        async submitForm() {
            const user = JSON.parse(localStorage.getItem('user'));

            if (!user) {
                console.error("User data is not available.");
                alert("User data is not available. Please log in again.");
                return;
            }

            if (!this.validateForm()) {
                return;
            }

            let formData = new FormData();
            formData.append('userID', user.id);
            formData.append('name', this.formData.name);
            formData.append('email', this.formData.email);
            if (this.formData.password) {
                formData.append('password', this.formData.password);
                formData.append('password_confirmation', this.confirmPassword);
            }

            if (this.formData.avatar && this.formData.avatar !== this.formData.originalAvatar) {
                formData.append('avatar', this.formData.avatar);
            } else if (this.formData.avatar === null && this.formData.originalAvatar) {
                formData.append('remove_image', true);  // Send flag to remove image
            }

            try {
                const response = await API.updateUserProfile(formData);
                this.errors = {};
                this.fetchUserProfile();
                alert('Profile updated successfully!');

            } catch (error) {
                if (error.response && error.response.status === 422) {
                    this.errors = error.response.data.errors;
                } else {
                    console.error('An unexpected error occurred:', error);
                }
            }
        },

        // Fetch user profile data from API
        fetchUserProfile() {
            const user = JSON.parse(localStorage.getItem('user'));
            if (!user) return;

            API.get_edit_user(
                user.id,
                (data) => {
                    this.formData = {
                        ...data,
                        confirmPassword: '',
                        avatar: data.avatar,
                        originalAvatar: data.avatar,
                        avatar_preview: null
                    };
                    this.formData.originalAvatar = this.formData.avatar;
                    this.formData.avatar_preview = null;
                },
                (error) => {
                    console.error("Error fetching user profile:", error);
                }
            );
        },
    },

    mounted() {
        this.fetchUserProfile();
    },
    computed: {
        confirmPassword() {
            return this.formData.password ? this.formData.confirmPassword : '';
        },
        profileImageSrc() {
            if (this.formData.avatar_preview) {
                return this.formData.avatar_preview;
            } else if (this.formData.avatar) {
                return this.formData.avatar;
            } else {
                return this.defaultProfilePicture; // Show default when null
            }

        }
    },
};
</script>

<style scoped>
.container {
    max-width: 600px;
}

.card {
    border-radius: 8px;
}

.img-thumbnail {
    object-fit: cover;
}
.Customer-card-body form input[type="file"] {
/* Style the color of the message that says 'No file chosen' */
  color: #878787;
}
.Customer-card-body form input[type="file"]::-webkit-file-upload-button {
  background: #70D3FF;
  border: 2px solid #70D3FF;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  padding: 10px 20px;
  text-transform: uppercase;
  transition: all 1s ease;
}
.form-group .remove-btn {
    display: block;
    width: 100% !important;
    border-radius: 5px !important;
    padding: 6px !important;
    font-size: 14px !important;
    color: #fff !important;
    border: 1px solid #70d3ff !important;
    background-color: #70d3ff !important;
    box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%) !important;
    font-weight: 500 !important;
    line-height: 1 !important;
}
.form-group .remove-btn:hover {
    color: #70d3ff !important;
    background-color: #fff !important;
    border-color: #70d3ff !important;
    outline: 0 !important;
    text-decoration: none;
}
.form-group .update-btn {
    border-radius: 8px !important;
}
</style>
